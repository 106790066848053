<template>
  <div>
    <v-dialog v-model="$store.state.changeImageDialog" width="400" persistent>
      <v-card flat class="card">
        <v-toolbar outlined flat color="grey lighten-4">
          <v-toolbar-title>
            {{ $t("edit-image") }}
          </v-toolbar-title>
          <v-spacer />
          <v-btn @click="close()" icon>
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-form @submit.prevent="submit()" ref="form">
          <v-container>
            <v-card outlined>
              <v-container>
                <v-row>
                  <v-col cols="12" md="12">
                    <h3 class="mb-2">
                      {{ $t("change-image") }}
                      <span class="required">*</span>
                    </h3>
                    <!-- <input
                      v-on:change="imageUrl"
                      type="file"
                      @change="handleFileChange"
                    /> -->
                    <v-file-input
                      dir="ltr"
                      v-model="imageUrl"
                      :placeholder="$t('change-image')"
                      :rules="[$global.state.required()]"
                      @change="uploadFile()"
                      type="file"
                      required
                      filled
                      outlined
                      accept="image/*"
                    />
                  </v-col>
                </v-row>
              </v-container>
            </v-card>
            <v-col cols="12" md="12">
              <v-row class="mt-2" justify="end">
                <v-btn
                  @click="close()"
                  x-large
                  color="error"
                  class="mx-2 btn"
                  outlined
                  >{{ $t("cancel") }}</v-btn
                >
                <v-btn
                  type="submit"
                  :loading="$global.state.loading"
                  x-large
                  color="primary"
                  class="btn"
                  >{{ $t("save") }}</v-btn
                >
              </v-row>
            </v-col>
          </v-container>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      item: null,
      imageUrl: null,
      file: null,
    };
  },

  mounted() {
    console.log(this.$store.state.itemDetails, "this is item detieals");

    this.reset();
    this.$eventBus.$on("fill-fields", () => {
      // console.log(this.$store.state.itemDetails);
      // this.item = this.$store.state.itemDetails;
    });
  },

  methods: {
    reset() {
      this.item = {};
    },

    submit() {
      if (this.$store.state.itemDetails.dialogType == "add") {
        this.addItem();
      } else {
        this.editItem();
      }
    },

    addItem() {
      this.$global.state.loading = true;
      this.$http
        .post(`${this.$route.meta.endPoint}`, this.item)
        .then((res) => {
          this.$service.success(this.$t("tm-alidhafh-bnjah"));
          this.$eventBus.$emit(`refresh`);
          this.close();
        })
        .catch((err) => {
          this.$service.faild(err.response.data.message);
        })
        .finally(() => (this.$global.state.loading = false));
    },
    uploadFile() {
      const formData = new FormData();
      formData.append("files", this.imageUrl);
      this.$http.post("UploadFile", formData).then((res) => {
        this.file = res.data.fileList[0].path;
        console.log(this.file, "this is response uplaod image");
      });
    },
    editItem() {
      this.$global.state.loading = true;
      console.log(this.file, "this is image url");
      this.item = {
        imageUrl: this.file,
        UserId: this.$store.state.itemDetails.id,
        OrganizationId: this.$store.state.itemDetails.user.organization.id,
        ProvinceId: this.$store.state.itemDetails.user.province.id,
        fullName: this.$store.state.itemDetails.user.fullName,
        userName: this.$store.state.itemDetails.user.userName,
        jobTitle: this.$store.state.itemDetails.user.jobTitle,
        address: this.$store.state.itemDetails.user.province.name,
        isActive: this.$store.state.itemDetails.user.isActive,
        gender: this.$store.state.itemDetails.user.gender,
        birthday: this.$store.state.itemDetails.user.birthday,
        roleName: this.$store.state.itemDetails.user.role,
      };

      this.$http
        .put(`User/${this.$store.state.itemDetails.id}`, this.item)
        .then((res) => {
          this.close();
          this.$service.success(this.$t("tm-altadyl-bnjah"));
          this.$eventBus.$emit(`refresh`);
        })
        .catch((err) => {
          this.$service.faild(err.response.data.message);
        })
        .finally(() => (this.$global.state.loading = false));
    },

    close() {
      this.$store.commit("setChangeImageDialog");
      this.reset();
      this.resetValidation();
      this.$store.commit("setItemDetails", {});
    },

    resetValidation() {
      this.$refs.form.resetValidation();
    },
  },
};
</script>
